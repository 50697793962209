import { useEffect, useContext } from "react"
import Login from "./topia/pages/auth/Login"
import Index from "./topia/pages/index/Index"
import { AuthContext } from "./topia/context/authContext"
import SchedulePage from "./topia/pages/schedule/SchedulePage"
import { useNavigate, createBrowserRouter, RouterProvider } from "react-router-dom"

const AuthRoute = ({ children }) => {
  const navigate = useNavigate()
  const { isLogged } = useContext(AuthContext)

  useEffect(() => {
    if (!isLogged) navigate('/')
  }, [isLogged, navigate])

  return children
}
function App() {

  const router = createBrowserRouter([
    { path: '/', element: <AuthRoute><Login /></AuthRoute> },
    { path: 'home', element: <AuthRoute><Index /></AuthRoute> },
    { path: 'schedules', element: <AuthRoute><SchedulePage /></AuthRoute> },
  ])

  return <RouterProvider router={router} />
}

export default App
