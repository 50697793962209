import {
	Select,
} from "antd"
import { ScheduleTable } from "./components/ScheduleTable"

const SchedulePage = () => {
	return (
		<>
			<div className="big-wrapper">
				<p className="big-wrapper-title">Selector de instructor</p>
				<div className="dark-blue-container" style={{ paddingTop: "20px" }}>
					<div className="green-container">
						<p className="big-wrapper-title">Miércoles, 9 de enero del 2023</p>
						<div className="gray-container">
							<div className="gray-container-content">
								<p className="reservation-card-type">SELECCIONAR INSTRUCTOR</p>
								<Select style={{ width: "100%" }}></Select>
								<div className="schedule-wrapper">
									<ScheduleTable data={null} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SchedulePage;
