import axios from "axios"

const getReserveById = async (idReserve) => {
  try {
    const response = await axios.get(`https://topiadev.topiaapp.com/api/reservas/qr?booking_id=${idReserve}`)

    return {
      success: true,
      ...response,
    }
  } catch (error) {
    return {
      success: false,
      ...error.response,
    }
  }
}

export default getReserveById