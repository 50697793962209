import { FirebaseAuth } from "../../firebase/firebase"

const postSignOutFirebaseaAuth = async () => {
  try {
    const response = await FirebaseAuth.signOut()

    return{
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error
    }
  }
}

export default postSignOutFirebaseaAuth
