import "./styles.css"
import "../../../App.css"
import useUser from "../../hooks/useUser"
import { useState } from "react"
import QRCodeModal from "./Modal/QRCodeModal"
import useReserve from '../../hooks/useReserve'
import { DownOutlined } from "@ant-design/icons"
import { Content, Footer } from "antd/es/layout/layout"
import FooterItems from './../../components/FooterItems'
import DrawerReserve from "../../components/DrawerReserve"
import { Col, Row, Button, Layout, Dropdown, Space } from "antd"

const Index = () => {
  const [qrModalActive, setQrModalActive] = useState(false)
  const [reservationDrawerActive, setReservationDrawerActive] = useState(false)

  const { signOutSession } = useUser()
  const { reserve, getReserveId, getReserveDetailById } = useReserve()

  const handleLogout = async () => await signOutSession()
  const handleOpenCloseModal = () => setQrModalActive(state => !state)
  const handleOpenCloseDrawer = () => setReservationDrawerActive(state => !state)

  const items = [{
    key: '1',
    danger: true,
    label: (
	  <a target="_blank" rel="noopener noreferrer" onClick={handleLogout}>
		Salir
	  </a>
	)}]

  return (
	<div className="wrapper">
		<Layout>
		  <Content>
		  	<div className="big-wrapper">
				<div className="wrapper-header">
				  <img className="topia-logo" src="/assets/logo_topia.svg" alt="logo" />
				  <div className="flexbox-end">
					<Dropdown menu={{ items }}>
					  <a onClick={(e) => e.preventDefault()}>
						<Space>
						  <label style={{ color: '#FFFFFF' }}>Administrador</label>
						<img
						  className="topia-logo"
						  src="/assets/user-profile.png"
						  alt="logo"
						/>
						<DownOutlined />
						</Space>
					  </a>
					</Dropdown>
				  </div>
				</div>
				<div className="dark-blue-container">
					<div className="intro-description">
						<p>
							A partir del 24 de enero activaremos el servicios de Topia Parking
							para ingresar, validar y pagar tu parqueo de una forma rápida y
							sencilla.
						</p>
					</div>
					<div className="main-container">
						<Row>
							<Col span={24} align="middle">
								<h5 className="title-text mid-block">VERIFICAR RESERVAS:</h5>
							</Col>
							<Col span={24} align="middle">
								<img
									className="mid-block"
									src="/assets/qr_pretty.png"
									alt="logo"
								/>
							</Col>
							<Col span={24} align="middle" className="final-block">
								<Button
									htmlType="button"
									style={{
										color: "#10b48c",
										borderColor: "#10b48c",
										background: "#10b48c",
									}}
									shape="round"
									size="large"
									onClick={handleOpenCloseModal}
								>
									<span className="button-text">ESCANEAR CÓDIGO DE ACCESO</span>
								</Button>
							</Col>
						</Row>
					</div>
				</div>
			</div>

			<QRCodeModal
			  getReserveId={getReserveId}
			  isOpenModal={qrModalActive}
			  handleOpenCloseModal={handleOpenCloseModal}
			  handleOpenCloseDrawer={handleOpenCloseDrawer}
			/>

			<DrawerReserve
			  reserve={reserve}
			  getReserveDetailById={getReserveDetailById}
			  handleOpenCloseDrawer={handleOpenCloseDrawer}
			  reservationDrawerActive={reservationDrawerActive}
			/>
		  </Content>
		  <Footer style={{ padding: "10px" }}>
			<FooterItems />
		  </Footer>
		</Layout>
	</div>
  )
}

export default Index;
