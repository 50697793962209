import { useContext } from "react"
import { toast } from "react-toastify"
import { AuthContext } from "../context/authContext"
import postFirebaseAuth from './../services/postFirebaseAuth'
import postSignOutFirebaseaAuth from "../services/postSignOutFirebaseaAuth"
import getQueryDocByCollection from "../helpers/getQueryDocByCollection"

const useUser = () => {
  const { isLogged, toggleSession } = useContext(AuthContext)

  const startLoginSession = async (email, password) => {
    const response = await postFirebaseAuth(email, password)

    if (!(response.success)) {
      toast.warning('Credenciales inválidas')
      return false
    }

    const committee = await getQueryDocByCollection('/committee', "user_id", "==", response.user.uid)
        
    // Verificar committee
    if ( committee.length < 1 ) return signOutSession()

    // Verificar residential in committee
    if(!committee[0].residential_id) return signOutSession()

    // Verificar el rol de la residencial
    const residenciaData = await getQueryDocByCollection('/residentials', "idResidential", "==", committee[0].residential_id)
    const rolResidential = residenciaData[0].entity_type.path
    
    toggleSession()
    toast.success('Login iniciado con exito')
    return true
  }

  const signOutSession = async () => {
    const response = await postSignOutFirebaseaAuth()

    if (response.success) {
      toggleSession()
      toast.success('Cerrado de sesión con éxito')
      return true
    }

    toast.warning("No se pudo cerrar sesión")
    return false
  }

  return {
    isLogged,
    toggleSession,
    signOutSession,
    startLoginSession,
  }
}

export default useUser
