import { collection, getDocs, query, where } from "firebase/firestore"
import { FirebaseDB } from "../../firebase/firebase"

const getQueryDocByCollection = async (
	collectionName,
	conditionField,
	operator,
	param
) => {
  const collectionRef = collection(FirebaseDB, collectionName);

  // Obtenemos solo las activas
  const queryData = query(
    collectionRef,
    where(conditionField, operator, param)
  )

  const querySnapshot = await getDocs(queryData);
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

  const data = []
  
  querySnapshot.forEach(async (documment) => {
    let doc = documment.data()
    data.push({ id: documment.id, ...doc })
  })

  return data
  }

export default getQueryDocByCollection
