import { createContext, useState } from "react"
// 1. crear el contexto
export const AuthContext = createContext()

// 2. crear el provider para proveer el contexto
export const AuthProvider = ({ children }) => {
  const [isLogged, setIdLogged] = useState(false)
  const [idReserve, setIdReserve] = useState('')

  const toggleSession = () => setIdLogged(state => !state)

  return (
    <AuthContext.Provider value={{ isLogged, toggleSession, idReserve, setIdReserve }}>
      {children}
    </AuthContext.Provider>
)}
