import { useContext, useState } from "react"
import { Modal, Row, Col } from "antd"
import SpinLoader from './../../../components/SpinLoader'
import Html5QrcodePlugin from "../../../components/Html5QrcodePlugin"
import { AuthContext } from "../../../context/authContext"

const QRCodeModal = ({ handleOpenCloseDrawer, isOpenModal, handleOpenCloseModal, getReserveId }) => {
  const [loading, setLoading] = useState(false)
  const { setIdReserve } = useContext(AuthContext)

  const onFinish = (response) => {
    if(response.success) {
      setIdReserve(response.idReserve)
      handleOpenCloseModal()
      handleOpenCloseDrawer()
      return
    }
    return null
  }

  const onNewScanResult = async (decodedText, decodedResult) => {
    setLoading(true)

    const response = await getReserveId(decodedText)
    onFinish(response)

    setLoading(false)
  }

  let body = <Html5QrcodePlugin fps={30} qrbox={300} disableFlip={false} qrCodeSuccessCallback={onNewScanResult} />

  if (loading) body = <SpinLoader />
  
  return (
    <Modal
      centered
      open={isOpenModal}
      onCancel={handleOpenCloseModal}
      footer={null}
      width={600}
    >
      <Row justify="center" align="middle">
        <Col span={24}>
          <h1 style={{ textAlign: "center", marginBottom: "10px" }}>Escanear codigo</h1>
          {body}
        </Col>
      </Row>
    </Modal>
  )
}

export default QRCodeModal
