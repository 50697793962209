import React from 'react'
import SpinLoader from './SpinLoader'
import { Button, Card, Drawer } from 'antd'
import { AuthContext } from '../context/authContext'
import { useState, useContext, useEffect } from 'react'

const DrawerReserve = ({
  reserve,
  getReserveDetailById,
  handleOpenCloseDrawer,
  reservationDrawerActive,
}) => {
  const [loading, setLoading] = useState(false)
  const { idReserve, setIdReserve } = useContext(AuthContext)

  const onFinish = (isSuccessfull) => {
    if (isSuccessfull) {
      setIdReserve('')
      return
    }
    return null
  }

  const getReserveById = async () => {
    setLoading(true)

    const response = await getReserveDetailById(idReserve)
    onFinish(response)

    setLoading(false)
  }

  useEffect(() => {
    if (idReserve) getReserveById()
  }, [idReserve])

  let body = <DrawerReserveData reserve={reserve} handleOpenCloseDrawer={handleOpenCloseDrawer} />

  if (loading) body = <SpinLoader />

  return (
    <Drawer title={
      <div className="reservation-drawer-header">
        <span className="reservation-drawer-header title">
            RESERVACIÓN
        </span>
        <br />
        <span className="reservation-drawer-header description">
            {loading ? 'Cargando...' : `${reserve?.boamenity?.name}`}
        </span>
      </div>
      }
      placement="bottom"
      closable={false}
      onClose={handleOpenCloseDrawer}
      open={reservationDrawerActive}
      key="bottom"
      footer={null}
      height={600}
      className="reservation-drawer"
    >
      {body}
    </Drawer>

  )
}

const DrawerReserveData = ({ reserve, handleOpenCloseDrawer }) => {
  const sizeArrayInvitees = reserve?.booking_invitees?.length ? reserve.booking_invitees.length : 0
  const sizeArrayInstructors = reserve?.booking_instructures?.length ? reserve.booking_instructures.length : 0
  const sizeArrayMembers = reserve?.booking_member_invitees?.length ? reserve.booking_member_invitees.length : 0

  return (
    <div className="drawer-container">
      <div className="flexbox-space-between">
          <div>
              <p className="drawer-time-header">Fecha autorizada:</p>
              <p className="drawer-time-description">{reserve?.date}</p>
          </div>
          <div>
              <p className="drawer-time-header">Horario autorizado:</p>
              <p className="drawer-time-description">{reserve?.timeBegin?.slice(0, 5)} a {reserve?.timeEnd?.slice(0, 5)}</p>
          </div>
      </div>
      <div>
          <Card className="reservation-card">
              <div className="reservation-card-container">
                  <div>
                      <p className="partners-list-description">MIEMBRO</p>
                      <p className="partners-list-title">
                        {reserve?.nameUser}
                      </p>
                  </div>
                  <div>
                      <p className="partners-list-description">
                          TIPO DE RESERVA:
                      </p>
                      <p className="reservation-card-type">{reserve?.typeGame?.toUpperCase()}</p>
                  </div>
              </div>
          </Card>
      </div>
      <div className="drawer-partners-title">
          <p>Compañeros de juego:</p>
      </div>
      <div>
          <p className="partners-list-description">INVITADOS</p>
          {sizeArrayInvitees
            ? <ul className="partners-list">
                {reserve?.booking_invitees?.map((invitee) => (
                  <li key={invitee.id}>
                    <p className="partners-list-title">{invitee.name}</p>
                    </li>
                ))}
            </ul>
            : <p style={{ color: '#324664', fontSize: '12px', fontWeight: 600, marginLeft: "5px" }}>
                No hay invitados
              </p>}

          <p className="partners-list-description">SOCIOS</p>
          {sizeArrayMembers
            ? <ul className="partners-list">
                {reserve?.booking_member_invitees?.map((member) => (
                  <li key={member.id}>
                    <p className="partners-list-title">
                      {`${member.MEMBER.MEMBER_INFORMATION.firstName} ${member.MEMBER.MEMBER_INFORMATION.lastName}`}
                    </p>
                    </li>
                ))}
            </ul>
            : <p style={{ color: '#324664', fontSize: '12px', fontWeight: 600, marginLeft: "5px" }}>
                No hay socio/s invitados
              </p>}

          <p className="partners-list-description">INSTRUCTORES</p>
          {sizeArrayInstructors
            ? <ul className="partners-list">
                {reserve?.booking_instructures?.map((instructure) => (
                  <li key={instructure.id} className="partners-list-item">
                    <p className="partners-list-title">{instructure.instructure.userName}</p>
                    </li>
                ))}
              </ul>
            : <p style={{ color: '#324664', fontSize: '12px', fontWeight: 600, marginLeft: "5px" }}>
                No hay instructor/es invitados
              </p>}
      </div>
      <div className="flexbox-center">
          <Button
            htmlType="button"
            style={{
              color: "#10b48c",
              borderColor: "#10b48c",
              background: "#10b48c",
            }}
            shape="round"
            size="large"
            onClick={handleOpenCloseDrawer}
          >
              <span className="button-text">AUTORIZAR INGRESO</span>
          </Button>
      </div>
    </div>
  )
}

export default DrawerReserve
