import "./styles.css"
import { Link } from "react-router-dom"

const FooterItems = () => (
	<div className="footer">
		<Link to="/home" style={{ textDecoration: "none", color: "inherit" }}>
			<div className="footer-content click-image">
				<img src="/assets/blue_check.png" alt="check" />
				<br />
				<span className="footer-text-blue">Autorizar</span>
			</div>
		</Link>
	</div>
)

export default FooterItems

