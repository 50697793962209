import axios from "axios"

const getIdReserveByQrCode = async (qrCodeAssignedToReserve) => {
  try {
    const response = await axios.post('https://topiadev.topiaapp.com/api/consultar-qr', { code: qrCodeAssignedToReserve })

    return {
      success: true,
      ...response
    }
    
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getIdReserveByQrCode