import "./index.css"
import App from "./App"
import { ConfigProvider } from "antd"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import { AuthProvider } from "./topia/context/authContext"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <AuthProvider>
	<ConfigProvider theme={{ token: { fontFamily: "Poppins" } }}>
	  <ToastContainer />
	  <App />
	</ConfigProvider>
  </AuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
