import { signInWithEmailAndPassword } from "firebase/auth"
import { FirebaseAuth } from "../../firebase/firebase"

const postFirebaseAuth = async (email, password) => {
  try {
    const response = await (signInWithEmailAndPassword(FirebaseAuth, email, password))

    return {
      success: true,
      ...response
    }

  } catch (error) {
    return {
      success: false,
      ...error
    }
  }
 
}

export default postFirebaseAuth
