import React from "react"
import { Spin } from "antd"

const SpinLoader = () => (
  <Spin
	tip="Cargando"
	size="large"
	style={{ color: "#16A07F", fontWeight: "500" }}
  >
  	<div style={{
	  padding: "50px",
	  background: "rgba(0,0,0,0.05)",
	  borderRadius: "4px",
    }}
  />
</Spin>

)

export default SpinLoader
