import './style.css'
import { Form, Row, Input, Button } from 'antd'
import React, { useState } from 'react'
import { UserOutlined, UnlockOutlined } from '@ant-design/icons'
import useUser from '../../hooks/useUser'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const { startLoginSession } = useUser()

  const onFinish = (isSuccessful) => {
    if (isSuccessful) {
      navigate('/home')
      return
    }
    return null
  }

  const onSubmit = async (data) => {
    setLoading(true)

    const response = await startLoginSession(data.email, data.password)

    onFinish(response)
    setLoading(false)
  }

  return (
    <Row className="wrapper-login">
      <Row className="login-box">
        <img className='logo' src="/assets/logo-login.png" alt="Topia - Logo" />
        <h2 id='login-slogan' style={{ margin: 0 }}>
          La nueva forma <br />
          de <span>convivir</span>
        </h2>

        <Form
          form={form}
          name="landing-login"
          onFinish={onSubmit}
          layout="vertical"
          disabled={loading}
        >
          <Form.Item name="email" className='form-group'>
            <Input
              size="large"
              placeholder="Usuario"
              prefix={<UserOutlined />}
            />
          </Form.Item>

          <Form.Item name="password" className='form-group'>
            <Input.Password
              size="large"
              placeholder="Contraseña"
              prefix={<UnlockOutlined />}
            />
          </Form.Item>

          <Button
            shape="round"
            htmlType="submit"
            loading={loading}
            className='form-group'
          >
            INGRESAR
          </Button>
        </Form>
      </Row>
    </Row>
  )
}

export default Login