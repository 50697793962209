import { useState } from "react"
import { toast } from "react-toastify"
import verifyResponse from "../helpers/verifyResponse"
import getIdReserveByQrCode from '../services/getIdReserveByQrCode'
import getReserveById from "../services/getReserveById"

const useReserves = () => {
  const [reserve, setReserve] = useState({})

  const getReserveId = async (qrCodeAssignedToReserve) => {
    const response = await getIdReserveByQrCode(qrCodeAssignedToReserve)

    if (response.data.status === 'success') {
      toast.success('Id de reserva obtenida con éxito')
      return { success: response.success, idReserve: response.data.document }
    }

    toast.warn(response.data.message)

    return { success: false, idReserve: '' }
  }

  const getReserveDetailById = async (idReserve) => {
    const response = await getReserveById(idReserve)

    if (response.success) setReserve(response.data.data)

    return verifyResponse(response.data, response.status, 'Informacion de reserva obtenida con éxito')
  }

  return {
    reserve,
    getReserveId,
    getReserveDetailById,
  }
}

export default useReserves
