import { useState } from "react";
import {
	Button,
	Tabs as AntdTabs,
	Descriptions,
} from "antd"
import "../styles.css"

export const ScheduleTable = ({ data }) => {
	const [isReserved, setIsReserved] = useState(false);
	return (
		<>
			<div className="schedule-wrapper">
				{/* HACER MAP HORARIO */}
				<Descriptions column={1} layout="horizontal" bordered>
					<Descriptions.Item label={"6:00 am"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
					<Descriptions.Item label={"7:00 am"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
					<Descriptions.Item label={"8:00 am"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
					<Descriptions.Item label={"9:00 am"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
					<Descriptions.Item label={"10:00 am"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
					<Descriptions.Item label={"11:00 am"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
					<Descriptions.Item label={"12:00 am"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
					<Descriptions.Item label={"1:00 pm"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
					<Descriptions.Item label={"2:00 pm"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
					<Descriptions.Item label={"3:00 pm"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
					<Descriptions.Item label={"4:00 pm"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
					<Descriptions.Item label={"5:00 pm"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
					<Descriptions.Item label={"6:00 pm"} labelStyle={{ width: "7rem" }}>
						{isReserved ? (
							<>
								<div className="flexbox-start">
									<span className="schedule-text">Hora reservada</span>
								</div>
							</>
						) : (
							<>
								<div className="flexbox-end">
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "#FFFFFF",
										}}
										shape="round"
										size="small"
									>
										<span
											className="button-text"
											style={{ color: "#10b48c", textTransform: "none" }}
										>
											Reservar
										</span>
									</Button>
								</div>
							</>
						)}
					</Descriptions.Item>
				</Descriptions>
			</div>
		</>
	);
};
